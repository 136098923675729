













































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_filtered_data.vue'
import controller from '@/app/ui/controllers/ManageDataPayrollController'
import { ManageDataPayroll } from '@/domain/entities/ManageDataPayroll'
import { Utils } from '@/app/infrastructures/misc'
import { EnumSort } from '@/app/infrastructures/misc/Constants/manageDataPayroll'
import ModalConfirm from '../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'
import { Location } from 'vue-router'

interface IOptions {
  label: string
  value: string | boolean | number
}

interface IParams {
  page: number
  per_page: number
  sort_by: IOptions
  keyword: string
}

interface IStyle {
  minWidth: string
  maxWidth: string
}

interface IDataCell {
  value: number | string | boolean | Array<IOptions | number>
  customStyle: IStyle
}

interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

@Component({
  components: {
    DropdownSelect,
    DataTableV2,
    Button,
    TextInput,
    PaginationNav,
    EmptyIllustration,
    Badge,
    ModalConfirm,
    ModalSuccess,
    Loading
  },
})
export default class ManageDataPayrollListPage extends Vue {
  controller = controller
  modalConfirmVisible = false
  modalSuccessVisible = false
  selectedData: Array<IOptions | number> | null = null

  sortOptions: Array<IOptions> = [
    { label: 'Terbesar', value: EnumSort.DESC },
    { label: 'Terendah', value: EnumSort.ASC },
  ]

  paginationOptions: Array<IOptions> = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  parameters: IParams = {
    page: 1,
    per_page: Number(this.paginationOptions[0].value),
    sort_by: this.sortOptions[0],
    keyword: '',
  }

  statusOptions: Array<IOptions> = [
    { label: 'Active', value: true },
    { label: 'Hold', value: false },
  ]

  headers: Array<IHeaderCell> = [
    this.headerCellMapper('No.', '5%'),
    this.headerCellMapper('Driver ID', '10%'),
    this.headerCellMapper('Nama Driver', '20%'),
    this.headerCellMapper('Phone Number', '15%'),
    this.headerCellMapper('Saldo Saat Ini', '10%'),
    this.headerCellMapper('Status', '10%'),
    this.headerCellMapper('Action', '30%'),
  ]

  manageDataPenghasilanList: Array<Array<IDataCell>> = []

  created(): void {
    this.fetchManageDataPayroll()
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      keyword: encodeURIComponent(this.parameters.keyword.trim()),
      sort_by: <string>this.parameters.sort_by.value,
    }
  }

  private fetchManageDataPayroll(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchManageDataPayroll(true)
  }, 400)

  private routeToDetail(id: number): Location {
    return {
      name: 'ManageDataPenghasilanDetail',
      params: {
        id: String(id)
      }
    }
  }

  private onExport(): void {
    window.open(
      'https://metabase.thelionparcel.com/question/1720-balance-history-courier-kvp?start_date=2022-10-21&end_date=2022-11-01'
    )
  }

  private onSelectOption(data: Array<IOptions | number>): void {
    const currentItem = controller.dataManageDataPayrollList.find(
      item => item.courierId === data[1]
    )

    if (currentItem) {
      const selectedOpt = <IOptions>data[0]

      if (currentItem.isActive !== selectedOpt.value) {
        this.selectedData = data
        this.modalConfirmVisible = true
      }
    }
  }

  private onCloseConfirmModal(): void {
    this.onResetOption()
    this.selectedData = null
    this.modalConfirmVisible = false
  }

  private onResetOption(): void {
    if (this.selectedData) {
      const id = this.selectedData[1]
      const idx = this.manageDataPenghasilanList.findIndex(
        item => item[1].value === id
      )
      const currStatus = <boolean>this.manageDataPenghasilanList[idx][5].value

      this.manageDataPenghasilanList[idx][6].value = [
        this.setStatusOption(currStatus),
        id,
      ]
    }
  }

  private onSubmit(): void {
    this.modalConfirmVisible = false

    if (this.selectedData) {
      const id = <number>this.selectedData[1]
      const opt = <IOptions>this.selectedData[0]

      controller.update({
        id: id,
        is_active: <boolean>opt.value,
      })
    }

    this.selectedData = null
  }

  private onChangePaginationOption(perPageAmount: number): void {
    this.parameters.per_page = perPageAmount
    this.fetchManageDataPayroll(true)
  }

  private indexTable(idx: number): string {
    const getCountData = (this.parameters.page - 1) * this.parameters.per_page
    return String(getCountData + idx + 1)
  }

  private setBadgeStyle(isActive: boolean): string {
    if (isActive) {
      return 'success'
    } else {
      return 'error'
    }
  }

  private setStatusOption(isActive: boolean): IOptions {
    const res = this.statusOptions.find(item => item.value === isActive)
    return <IOptions>res
  }

  private tableCellMapper(
    value: string | number | boolean | Array<IOptions | number>,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataManageDataPayrollList')
  setManageDataPayroll(data: ManageDataPayroll[]): void {
    if (data) {
      this.manageDataPenghasilanList = data.map((item, index) => {
        return [
          this.tableCellMapper(this.indexTable(index), '5%'),
          this.tableCellMapper(Number(item.courierId), '10%'),
          this.tableCellMapper(String(item.courierName), '20%'),
          this.tableCellMapper(String(item.phoneNumber), '15%'),
          this.tableCellMapper(
            `Rp${Utils.currencyDigit(Number(item.balance))}`,
            '10%'
          ),
          this.tableCellMapper(Boolean(item.isActive), '10%'),
          this.tableCellMapper(
            [
              this.setStatusOption(Boolean(item.isActive)),
              Number(item.courierId),
            ],
            '30%'
          ),
        ]
      })
    }
  }

  @Watch('controller.isUpdateManageDataPayrollSuccess')
  setUpdateManageDataPayrollSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.controller.setUpdateManageDataPayrollSuccess(false)
    }
    this.fetchManageDataPayroll()
  }
}
