function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-row justify-between mt-1 mb-14"},[_c('h2',{staticClass:"font-bold text-gray-4"},[_vm._v("Manage Data Penghasilan")]),_c('div',{staticClass:"flex flex-col gap-3"},[_c('Button',{staticClass:"w-32-a py-2 text-xs",on:{"click":_vm.onExport}},[_vm._v(" Export to Excel ")])],1)]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-full flex flex-col gap-2"},[_c('span',{staticClass:"filter-label"},[_vm._v("Cari Data Penghasilan")]),_c('TextInput',{attrs:{"placeholder":"Masukkan DriverID, Nama Driver, atau Phone Number","type":"search","customClass":"hide-button-clear"},on:{"input":_vm.onFilterList},model:{value:(_vm.parameters.keyword),callback:function ($$v) {_vm.$set(_vm.parameters, "keyword", $$v)},expression:"parameters.keyword"}})],1),_c('div',{staticClass:"w-96 flex flex-col gap-2 ml-4"},[_c('span',{staticClass:"filter-label"},[_vm._v(" Sort By Saldo ")]),_c('DropdownSelect',{attrs:{"allowEmpty":false,"clearOnSelect":false,"searchable":false,"options":_vm.sortOptions},on:{"input":_vm.onFilterList},model:{value:(_vm.parameters.sort_by),callback:function ($$v) {_vm.$set(_vm.parameters, "sort_by", $$v)},expression:"parameters.sort_by"}})],1)]),(_vm.controller.isLoading)?_c('div',{staticClass:"flex justify-center mt-8"},[_c('Loading')],1):_c('div',{staticClass:"w-full overflow-x-auto mt-8"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.manageDataPenghasilanList},scopedSlots:_vm._u([{key:"0",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_vm._v(" "+_vm._s(data.data.value)+". ")]}},{key:"2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('span',{staticClass:"pr-10 text-sm break-normal"},[_vm._v(_vm._s(data.data.value))])]}},{key:"5",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('Badge',{staticClass:"w-full w-32-a flex justify-center px-4 py-1 text-center hdpi-laptop:w-3/4",attrs:{"type":_vm.setBadgeStyle(data.data.value),"customPadding":true}},[_c('span',{staticClass:"break-normal"},[_vm._v(" "+_vm._s(data.data.value ? 'Active' : 'Hold')+" ")])])]}},{key:"6",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"w-full gap-2 grid grid-cols-3"},[_c('div',{staticClass:"col-span-1"},[_c('DropdownSelect',{attrs:{"openDirection":"above","allowEmpty":false,"clearOnSelect":false,"searchable":false,"options":_vm.statusOptions},on:{"input":function () { return _vm.onSelectOption(data.data.value); }},model:{value:(data.data.value[0]),callback:function ($$v) {_vm.$set(data.data.value, 0, $$v)},expression:"data.data.value[0]"}})],1),_c('div',{staticClass:"flex items-end col-span-2"},[_c('Button',{attrs:{"routeTo":_vm.routeToDetail(data.data.value[1])},on:{"click":function($event){_vm.$router.push(_vm.routeToDetail(data.data.value[1]))}}},[_c('span',{staticClass:"text-xs"},[_vm._v("Detail")])])],1)])]}}])},[_c('div',{staticClass:"flex flex-col w-full justify-center items-center gap-2 py-24",attrs:{"slot":"data-empty"},slot:"data-empty"},[_c('EmptyIllustration'),_c('span',{staticClass:"font-medium mt-6"},[_vm._v("Tidak Menemukan Hasil Sesuai Filter")]),_c('span',{staticClass:"font-light text-gray-1"},[_vm._v("Silahkan ubah kata kunci atau reset filter")])],1)])],1),_c('PaginationNav',{staticClass:"my-4",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.per_page,"enablePerPageOption":true,"perPageOptions":_vm.paginationOptions,"totalItem":_vm.controller.paginationData.totalItem},on:{"onChangeOption":function (e) { return _vm.onChangePaginationOption(e); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.fetchManageDataPayroll()
      })($event)},"input":function () {
        _vm.fetchManageDataPayroll()
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmVisible,"title":"Yakin ingin melakukan ubah status??","description":"Pastikan status yang anda pilih sudah sesuai"},on:{"cancel":function () { return _vm.onCloseConfirmModal(); },"action":function () { return _vm.onSubmit(); }}}),_c('ModalSuccess',{attrs:{"visible":_vm.modalSuccessVisible,"title":"Status Berhasil Diubah","description":""},on:{"close":function () {
        _vm.modalSuccessVisible = false
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }